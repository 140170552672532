import React, { Fragment } from 'react';

import { Action } from 'ui/components';
import { Layout, Helmet } from 'ui/partials';

const ErrorPage = props => (
  <Fragment>
    <Helmet {...props} title="Error" />
    <Layout {...props}>
      <span>404 Error</span>
      <h1>Oh noes ;-(</h1>
      <p>
        There seems to be an issue with the thing you were looking for. You either typed in a wrong address or clicked
        on a broken link. Bummers.
      </p>
      <Action to="/">Return Home</Action>
    </Layout>
  </Fragment>
);

export default ErrorPage;
